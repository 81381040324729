.container {
    align-self: center;
    max-width: 50vw;
    margin: 50px 0px;
}

.container h2 {
    text-align: center;
    font-size: 30px;
    font-weight: 800;
    color: #EC6715;
}

.container h2:last-of-type {
    margin-top: 70px;
}

.container p,
.container i {
    font-size: 20px;
    font-weight: 400;
    text-align: justify;
    color: #00000099;
}

.container p>span {
    text-decoration: underline;
}

.container a {
    color: inherit;
}

.container button {
    color: #EC6715;
    background-color: transparent;
    width: 200px;
    height: 50px;
    position: fixed;
    bottom: 20px;
    border-radius: 10px;
    border: none;
    font-size: 20px;
    font-weight: 700;
    cursor: pointer;
    border: 1px solid #EC6715;
    backdrop-filter: blur(1px);
    left: 50%;
    transform: translateX(-50%);
}


@media (max-width: 2000px) {
    .container {
        max-width: 60vw;
    }
}

@media (max-width: 1700px) {
    .container {
        max-width: 70vw;
    }
}

@media (max-width: 600px) {
    .container {
        max-width: 80vw;
    }

    .container p,
    .container i {
        font-size: 14px;
    }
}

@media (max-width: 500px) {
    .container {
        max-width: 90vw;
    }
}