.firtContainer {
    display: flex;
    flex-direction: row;
    max-width: 80vw;
    align-self: center;
    overflow: hidden;
    margin-top: 100px;
}

.firtContainer h1 {
    color: #666666;
    font-size: 80px;
}

.firtContainer p {
    color: #666666;
    font-weight: 400;
    font-size: 25px;
}

.firtContainer h1 span {
    color: #EC6715;
}

.firtContainer div img {
    max-width: 850.69px;
    max-height: 729px;
}

.secondContainer {
    display: flex;
    flex-direction: row;
    min-width: 90vw !important;
    align-self: flex-end;
    justify-content: space-between;
    margin-top: 100px;
}


.secondContainer div:last-of-type img:last-of-type {
    max-width: 676px;
    max-height: 954px;
}

.secondContainer div:last-of-type img:first-of-type {
   display: none;
}

.secondContainer h2 {
    color: #666666;
    font-size: 50px;
}

.secondContainer h2:first-of-type {
    font-weight: 400;
}

.secondContainer h2 span {
    color: #EC6715;
    font-weight: 700;
}

.thirdContainer {
    display: flex;
    flex-direction: row;
    min-width: 80vw;
    align-self: center;
    gap: 15px;
}

.thirdContainer .step {
    width: 365px;
}

.thirdContainer .step div:first-of-type {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
}

.thirdContainer .step div:first-of-type img {
    width: 51px;
    height: 51px;
}

.thirdContainer .step h2 {
    color: #666666;
    font-size: 50px;
    font-weight: 700;
}

.thirdContainer .step p {
    color: #666666;
    font-size: 25px;
    font-weight: 400;
}

.thirdContainer .step div:first-of-type div {
    width: 312px;
    height: 5px;
    background-color: #EC6715;
    border-radius: 5px;
}

.goodLuck {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.goodLuck h2 {
    color: #EC6715;
    font-size: 50px;
}

.fourContainer {
    display: flex;
    flex-direction: column;
    width: 80vw;
    align-self: center;
    text-align: center;
}

.fourContainer p {
    font-size: 40px;
    color: #666666;
    font-weight: 700;
}

.fourContainer p span {
    color: #EC6715;
}

.fourContainer h2 {
    font-size: 50px;
    color: #666666;
    font-weight: 700;
}

.fourContainer p:last-of-type {
    margin-top: 0px;
}

.secondContainer div:first-of-type video {
    max-width: 843px;
    max-height: 471px;
}

.containerNewPrizes {
    display: flex;
    align-items: center;
    justify-content: center;
}

.containerNewPrizes img {
    max-width: 800px;
    width: 100%;
}

@media (max-width: 1544px) {
    .firtContainer {
        flex-wrap: wrap;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        max-width: 90vw;
    }

    .firtContainer div img,
    .secondContainer div:first-of-type img {
        max-width: 100%;
    }

    .secondContainer div:first-of-type video {
        width: 100%;
        height: auto;
    }

    .secondContainer {
        flex-wrap: wrap;
        gap: 50px;
        max-width: 90vw;
        align-self: center;
    }

    .secondContainer div:last-of-type img:first-of-type {
        max-width: 70%;
        height: 70%;
        display: inline-block;
        margin-right: 20px;
        /* position: absolute;
        left: 10vw; */
    }

    .secondContainer div:last-of-type img:last-of-type {
        max-width: 90%;
        margin-right: -5vw;
    }

    .secondContainer div:last-of-type {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: start;
    }
}

@media (max-width: 1253px) {
    

    .secondContainer div:last-of-type img:first-of-type {
        max-width: 70%;
        height: 70%;
        display: none;
    }
}

@media (max-width: 1451px) {
    .thirdContainer {
        flex-direction: column;
        max-width: 80vw;
        align-self: flex-start;
    }

    .thirdContainer .step {
        display: flex;
        flex-direction: row;
    }

    .thirdContainer .step div:first-of-type {
        flex-direction: column;
    }

    .thirdContainer .step:nth-child(1) div:first-of-type {
        gap: calc(50% - 30px);
    }

    .thirdContainer .step:nth-child(2) div:first-of-type {
        gap: calc(50% - 30px);

    }

    .thirdContainer .step:nth-child(3) div:first-of-type {
        gap: calc(50% - 30px);

    }

    .thirdContainer .step div:last-of-type p {
        width: calc(100vw - 100vw * 0.3);
    }

    .thirdContainer .step div:last-of-type h2 {
        margin-top: 0px;
    }

    .thirdContainer .step div:first-of-type div {
        transform: rotate(90deg);
    }

    .thirdContainer .step div:first-of-type {
        min-width: 100px !important;
    }

    .thirdContainer .step:nth-child(1) div:first-of-type div {
        width: 100px !important;
    }

    .thirdContainer .step:nth-child(2) div:first-of-type div {
        width: 150px !important;
    }

    .thirdContainer .step:nth-child(3) div:first-of-type div {
        width: 150px !important;
    }

    .goodLuck {
        flex-direction: row;
        align-items: center;
        gap: 10px;
        margin-left: 30px;
        margin-top: -50px;
    }

}

@media (max-width: 1369px) {
    .thirdContainer .step:nth-child(3) div:first-of-type {
        gap: calc(50% - 30px);

    }

    .thirdContainer .step:nth-child(3) div:first-of-type div {
        width: 190px !important;
    }
}

@media (max-width: 1000px) {
    .thirdContainer .step:nth-child(3) div:first-of-type {
        gap: calc(50% - 50px);

    }

    .thirdContainer .step:nth-child(3) div:first-of-type div {
        width: 190px !important;
        /* width: 100vh; */
    }
}

@media (max-width: 776px) {
    .thirdContainer .step:nth-child(3) div:first-of-type {
        gap: calc(50% - 30px);

    }

    .thirdContainer .step:nth-child(3) div:first-of-type div {
        width: 220px !important;
        /* width: 100vh; */
    }
}

@media (max-width: 886px) {
    .secondContainer div:first-of-type video {
        width: 100%;
        height: auto;
    }
}

@media all and (min-width: 701px) and (max-width: 1451px) {
    .thirdContainer .step div:last-of-type h2 {
        line-height: 50px;
    }
}

@media (max-width: 700px) {
    .firtContainer h1 {
        font-size: 50px;
    }

    .firtContainer p {
        font-size: 20px;
    }

    .secondContainer h2 {
        font-size: 30px;
    }

    .secondContainer h2:nth-child(1) {
        margin-top: -50px;
    }

    .thirdContainer .step h2 {
        font-size: 40px;
    }

    .thirdContainer .step p {
        font-size: 20px;
    }

    .thirdContainer .step div:first-of-type img {
        width: 31px;
        height: 31px;
        margin-top: 15px;
    }

    .goodLuck h2 {
        font-size: 40px;
    }

    .goodLuck img {
        width: 31px;
    }

    .fourContainer p {
        font-size: 25px;
    }


    .fourContainer h2 {
        font-size: 40px;
    }

    .thirdContainer .step div:first-of-type {
        min-width: 80px !important;
    }

    .thirdContainer .step:nth-child(1) div:first-of-type {
        gap: calc(50% - 20px);

    }

    .thirdContainer .step:nth-child(1) div:first-of-type div {
        width: 80px !important;
    }

    .thirdContainer .step:nth-child(2) div:first-of-type {
        gap: calc(50% - 30px);

    }

    .thirdContainer .step:nth-child(2) div:first-of-type div {
        width: 120px !important;
    }

    .thirdContainer .step:nth-child(3) div:first-of-type {
        gap: calc(50% - 30px);

    }

    .thirdContainer .step:nth-child(3) div:first-of-type div {
        width: 170px !important;
    }

}


@media (max-width: 430px) {
    .thirdContainer .step div:first-of-type {
        width: 50px !important;
    }

    .thirdContainer .step:nth-child(1) div:first-of-type {
        gap: calc(50% - 20px);

    }

    .thirdContainer .step:nth-child(1) div:first-of-type div {
        width: 80px !important;
    }

    .thirdContainer .step:nth-child(2) div:first-of-type {
        gap: calc(50% - 30px);

    }

    .thirdContainer .step:nth-child(2) div:first-of-type div {
        width: 180px !important;
    }

    .thirdContainer .step:nth-child(3) div:first-of-type {
        gap: calc(50% - 30px);

    }

    .thirdContainer .step:nth-child(3) div:first-of-type div {
        width: 230px !important;
    }

    .thirdContainer .step {
        width: 90vw;
    }
}

@media (max-width: 360px) {
    .firtContainer h1 {
        font-size: 35px;
    }

    .firtContainer p {
        font-size: 16px;
    }

    .secondContainer h2:first-of-type {
        font-size: 20px;
    }

    .thirdContainer .step h2 {
        font-size: 30px;
    }

    .thirdContainer .step p {
        font-size: 16px;
    }

    .thirdContainer .step div:first-of-type img {
        width: 22px;
        height: 22px;
    }

    .thirdContainer .step div:first-of-type {
        gap: 130px;
    }

    .thirdContainer .step div:first-of-type div {
        transform: rotate(90deg);
        margin-bottom: 200px;
        width: 200px;
    }

    .goodLuck {
        margin-top: 0px;
    }

    .goodLuck h2 {
        font-size: 30px;
    }

    .goodLuck img {
        width: 29px;
    }

    .fourContainer p {
        font-size: 16px;
    }

    .fourContainer h2 {
        font-size: 20px;
    }


    .thirdContainer .step div:last-of-type {
        height: 100% !important;
    }

    .thirdContainer .step:nth-child(1) div:first-of-type {
        gap: 50px !important;
        margin-bottom: -100%;

    }

    .thirdContainer .step:nth-child(1) div:first-of-type div {
        width: 80px !important;
        height: 5px !important;
    }

    .thirdContainer .step:nth-child(2) div:first-of-type {
        gap: 80px !important;
        margin-bottom: -100%;

    }

    .thirdContainer .step:nth-child(2) div:first-of-type div {
        width: 140px !important;
        height: 5px !important;
    }

    .thirdContainer .step:nth-child(3) div:first-of-type {
        gap: 110px !important;
        margin-bottom: -100%;

    }

    .thirdContainer .step:nth-child(3) div:first-of-type div {
        width: 190px !important;
        height: 5px !important;
    }
}