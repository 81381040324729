.firstContainer {
    display: flex;
    flex-direction: row;
    width: 80vw;
    align-self: center;
    flex: 1;
    gap: 120px;
    margin-bottom: 50px;
}

.firstContainer .left {
    display: flex;
    flex-direction: column;
    flex: 3;
    align-items: center;
}

.firstContainer .left img {
    width: 347px;
    height: 582px;
}

.firstContainer .left div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.firstContainer .left div p {
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    color: #666666;
}

.firstContainer .left div p span {
    color: #EC6715;
}

.firstContainer .left div video {
    width: 409px;
    height: 228px;
}

.firstContainer .right {
    display: flex;
    flex-direction: column;
    flex: 7;
    max-width: 60% !important;
}



.firstContainer .right p,
.dinamicText {
    font-size: 40px;
    font-weight: 700;
    text-align: center;
    color: #666666;
    margin: 20px 0px 10px;
}

.firstContainer .right .typography {
    font-size: 32px;
    color: #666666;
    margin: 50px 0px;
}

.firstContainer .right .typography span {
    color: #EC6715;
}

.firstContainer .right .containerArrows {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 20px;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.firstContainer .right .containerArrows div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.firstContainer .right .containerArrows div p {
    font-size: 20px;
    font-weight: 800;
    text-align: center;
    color: #666666;
}

.firstContainer .right .containerArrows div img {
    width: 63.95px;
    height: 87.41px;
}

.firstContainer .right .containerArrows div:nth-child(2n + 2) img {
    transform: rotateY(180deg);
}

.firstContainer .right .containerArrows div div {
    width: auto;
    background-color: #EC6715;
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 24px;
    font-weight: 800;
    padding: 5px 10px;

}

.giant {
    font-size: 64px;
    font-weight: 800;
    color: #EC6715;
}

.firstContainer .right .containerLastWoman {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 50px;
    width: 100% !important;
}

.firstContainer .right .containerLastWoman img {
    width: 195px;
    height: 244px;
}

.firstContainer .right .containerLastWoman p {
    font-size: 48px;
    font-weight: 800;
    text-align: center;
    color: #EC6715;
}

.lastContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.lastContainer .social {
    background-color: #EC6715;
    min-width: 700px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 100px;
    border-radius: 0px 41px 0px 0px;
}

.lastContainer .social div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    gap: 30px;
}

.lastContainer .social div p,
.lastContainer .social div p>a {
    font-size: 24px;
    font-weight: 700;
    text-align: left;
    color: #fff;
    text-decoration-color: #ffff;
    max-width: 80%;

}

.lastContainer .social div div {
    background-color: #fff;
    width: 65px;
    height: 65px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.lastContainer .social div div a {
   text-decoration: none;
   display: flex;
}

.lastContainer .social div:first-child div img {
    width: 33px;
    height: 34px;
}

.lastContainer .social div:last-child div img {
    width: 46px;
    height: 44px;
}

.lastContainer .peoples {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.lastContainer .peoples img {
    width: 709.97px;
    height: 217.1px;
    margin-bottom: -22px;
}

.containerVideoHowTo {
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.containerVideoHowTo video {
    width: 100%;
    max-width: 700px;
}

.containerNewPrizes {
    display: flex;
    align-items: center;
    justify-content: center;
}

.containerNewPrizes img {
    max-width: 800px;
    width: 100%;
}

@media (max-width: 1514px) {
    .lastContainer {
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }

    .lastContainer .social {
        border-radius: 0px;
        max-width: 100vw;
    }

    .lastContainer .peoples img {
        margin-bottom: -2px;
    }
}

@media (max-width: 1250px) {
    .firstContainer {
        flex-wrap: wrap;
    }

    .firstContainer .right {
        max-width: 100% !important;
        align-self: center;

    }
}

@media (max-width: 951px) {
     .firstContainer .right .containerLastWoman {
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
}

@media (max-width: 806px) {

    .lastContainer .social div p,
    .lastContainer .social div p>a {
        font-size: 16px;
        width: 80%;
    }

    .lastContainer .social {
        gap: 5px;
        padding: 20px 0px 20px 10px;
        min-width: 90vw;
    }

    .lastContainer .peoples img {
        max-width: 100vw;
        height: auto;
    }

    .lastContainer .social div div {
        width: 55px;
        height: 55px;
    }
    
    .lastContainer .social div:first-child div img {
        width: 28px;
        height: 29px;
    }
    
    .lastContainer .social div:last-child div img {
        width: 37px;
        height: 36px;
    }
}

@media (max-width: 670px) {

    .firstContainer .right .containerArrows div p {
        font-size: 15px;
    }

    .firstContainer .right .containerArrows div img {
        width: 43.95px;
        height: 67.41px;
    }

    .firstContainer .right .containerArrows div div {
        font-size: 20px;

    }

    .giant {
        font-size: 54px;
    }

    .firstContainer .right p:first-of-type {
        font-size: 48px;
    }

    .firstContainer .right .win {
        font-size: 90px;
        line-height: 0px;
    }

    .firstContainer .left div p {
        font-size: 24px;
    }

    .firstContainer .right .typography {
        font-size: 16px;
    }
}

@media (max-width: 580px) {
    .firstContainer .right .containerLastWoman p {
        font-size: 32px;
        margin: 20px 0px;
    }

    .firstContainer .left div video {
        max-width: 80vw;
        height: auto;
    }

    .firstContainer .left img {
        width: 70vw;
        height: auto;
    }

    .dinamicText {
        font-size: 30px;
    }

    .giant {
        font-size: 36px;
    }
}

@media (max-width: 504px) {
    .firstContainer .right .containerArrows {
        gap: 5px;
    }

    .firstContainer .right .containerArrows div p {
        font-size: 12px;
    }

    .firstContainer .right .containerArrows div img {
        width: 32.11px;
        height: 56.64px;
    }

    .firstContainer .right .containerArrows div div {
        font-size: 16px;
        padding: 0px 5px;
    }

    .dinamicText {
        font-size: 20px;
    }

}