body {
    padding: 0;
    margin: 0;
    height: 100vh;
    width: 100%;
    background-image: url(./assets/imgs/background.png);
    font-family: 'Poppins', sans-serif;
}

.icon-img_active {
    filter: saturate(8);
}

.input_number_fav {
    width: 100%;
    border: none;
    background: transparent;
    font-size: 2.125rem;
    font-weight: bold;
    height: 100%;
    text-align: center;
    outline: 0;
    padding: 0;
}

input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;

}

input[type=number] {
    -moz-appearance: textfield;
    appearance: textfield;

}


.input_number_fav:focus {
    border: none;
    outline: 0;
    border-bottom: 2px solid black;
    max-width: 2rem;
}

.hidden_input_dynamic {
    border: none;
    background: transparent;
    position: absolute;
    outline: none;
    width: 0px;
}

.hidden_input_dynamic:focus {
    outline: none;
}

.paymentMadeClass {
    transition: all .5s;
    animation: paymentMadeClassAnimate 0.5s forwards;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column !important;
    flex-wrap: nowrap;
    text-align: center;
    height: 100vh;
    width: 100vw;
    background-color: #00A41099;
}

.paymentCancelClass {
    transition: all .5s;
    animation: paymentMadeClassAnimate 0.5s forwards;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column !important;
    flex-wrap: nowrap;
    text-align: center;
    height: 100vh;
    width: 100vw;
    background-color: rgba(237, 55, 31, 0.7);
}

.CreditAddedClass {
    transition: all .5s;
    animation: paymentMadeClassAnimate 0.5s forwards;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column !important;
    flex-wrap: nowrap;
    text-align: center;
    height: 100vh;
    width: 100vw;
    background-color: rgba(236, 102, 21, 0.7);
}

.containerLP {
    display: flex;
    flex-direction: column;
}

.rodape {
    max-width: 100vw;
    background-color: #666666;
    font-size: 16px;
    text-align: center;
    color: #fff;
    padding: 0px 50px;
}

.buttonNow {
    position: relative;
    background-color: #156BEC;
    color: #fff;
    font-size: 32px;
    text-align: center;
    border-radius: 10px;
    border: none;
    padding: 15px 20px;
    font-family: Poppins;
    font-weight: 700;
    max-width: 627px;
    align-self: center;
    transition-duration: 0.4s;
    -webkit-transition-duration: 0.4s;
    cursor: pointer;
    border: 1px solid #156BEC;
    animation: pulse 2s infinite;

}

.pulse {
    animation: pulse2 2s infinite;
}

.divStamp {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    width: auto !important;
    /* height: 78px; */
    animation: pulseWithoutBoxShadow 2s infinite;
}

.divStamp img {
    position: absolute;
    right: -80px !important;
    top: -10;
    cursor: pointer;
    width: 100px !important;
    height: auto !important;
}

.buttonNow:hover {
    transition-duration: 0.1s;
    background-color: #fff;
    color: #156BEC;
}

.buttonNow:after {
    content: "";
    display: block;
    position: absolute;
    border-radius: 10px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all 0.5s;
    box-shadow: 0 0 5px 10px #156BEC;
}

.buttonNow:active:after {
    box-shadow: 0 0 0 0 white;
    position: absolute;
    border-radius: 10px;
    left: 0;
    top: 0;
    opacity: 1;
    transition: 0s;
}

.color-number-0 {
    margin: 0;
    color: rgba(0, 82, 0, .9);
    display: inline;
}

.color-number-1 {
    margin: 0;
    color: rgba(0, 82, 0, .8);
    display: inline;
}

.color-number-2 {
    margin: 0;
    color: rgba(0, 82, 0, .7);
    display: inline;
}

.color-number-3 {
    margin: 0;
    color: #EC6615;
    text-decoration: underline;
    text-decoration-color: #bab7b6;
    display: inline;
}

.global-blocker {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent; 
    z-index: 9999; 
    pointer-events: all; 
  }


@media (max-width: 700px) {
    .rodape {
        font-size: 12px;
        padding: 0px 30px;
        text-align: left;
    }

    .buttonNow {
        font-size: 20px;
        border-radius: 5px;
        padding: 10px 15px;
        max-width: 70vw;
    }

    .divStamp img {
        right: -40px !important;
        width: 60px !important;
    }
}

@media (max-width: 466px) {
    .stampImg {
        width: 100px !important;
    }
}

@media (max-width: 410px) {
    .stampImg {
        width: 80px !important;
    }
}

@media (max-width: 371px) {
    .stampImg {
        width: 70px !important;
    }
}

@media (max-width: 360px) {
    .rodape {
        font-size: 14px;
        padding: 0px 20px;
    }

    .buttonNow {
        font-size: 16px;
    }
}

@keyframes paymentMadeClassAnimate {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes pulse2 {
    0% {
        transform: scale(1);
    }

    70% {
        transform: scale(.90);
    }

    100% {
        transform: scale(1);
    }
}


@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(21, 107, 236, .7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}

@keyframes pulseWithoutBoxShadow {
    0% {
        transform: scale(0.95);
    }

    70% {
        transform: scale(1);
    }

    100% {
        transform: scale(0.95);
    }
}

@keyframes pulseWithoutBoxShadowMore {
    0% {
        transform: scale(1);
    }

    70% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes rotate {
    from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.video-react .video-react-control-bar {
    background-color: rgba(230, 132, 12) !important;
}

.video-react .video-react-big-play-button {
    background-color: rgba(230, 132, 12) !important;
    border-radius: 50% !important;
    width: 3rem;
    height: 3rem;
}

.video-react .video-react-big-play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.no-scroll {
    overflow: hidden;
}