.nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow-x: hidden;
}

.nav img {
    width: 151px;
    height: 68px;
    margin: 30px 50px 0px 30px;
}

.ul {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.link {
    text-decoration: none;
}

.menuItemButton {
    color: #EC6615 !important;
    padding: 10px 16px !important;
    font-family: Poppins !important;
    font-weight: 700 !important;
}

.ul li {
    list-style-type: none;
    font-size: 20px;
    font-weight: 700;
    color: rgba(102, 102, 102, 1);
    text-align: center;
    padding: 25px;
    cursor: pointer;
}

.buttonDiv {
    display: flex;
    flex-direction: row;
    gap: 50px;
}

.buttonDiv div {
    margin-top: 2px;
    height: 50px;
    width: 1px;
    background-color: #000;
}

.buttonDiv button {
    font-size: 20px;
    font-weight: 700;
    color: #EC6615;
    text-align: center;
    width: 185px;
    height: 58px;
    background-color: transparent;
    border: 1px solid #EC6615;
    border-radius: 10px;
    cursor: pointer;
    margin-right: 50px;
    transition: all .1s ease-in-out;
    font-family: Poppins;
}

.buttonDiv button:hover {
    color: #fff;
    background-color: #EC6615;
}

.menuItem {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.divider {
    width: 100%;
    height: 1px;
    background-color: #D9D9D9;
}

@media (max-width: 1500px) {
    .ul li {
        font-size: 18px;
        padding: 23px;
    }
}

@media (max-width: 1415px) {
    .ul li {
        font-size: 16px;
        padding: 21px;
    }
}

@media (max-width: 500px) {
    .nav img {
        width: 96px;
        height: 44px;
    }
}