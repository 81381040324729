.container {
    display: flex;
    margin: 50px 0px;
    max-width: 100vw;
    flex: 1;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    gap: 50px;
}

.contentRight img {
    width: 500px;
    animation: pulseWithoutBoxShadow 2s infinite;
    border-radius: 70px;
}

.container .card30 a {
    display: flex;
    align-items: center;
    justify-content: center;
}

.container .card30 a img {
    max-width: 800px;
    width: 80%;
}

.container div:first-of-type {
    display: flex;
    flex-direction: column;
}

.container div:first-of-type>div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.container div:first-of-type>div>img:first-of-type {
    width: 1000px;
    margin-left: 0px;
}

.contentRight .containerUl {
    display: flex;
    align-items: center;
    justify-content: center;
}

.container .containerUl li {
    color: #666666;
    font-weight: 400;
    font-size: 25px;
}

.containerVideoHowTo {
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.containerVideoHowTo video {
    width: 90%;
    max-width: 700px;
}

.containerVideoHowTo p {
    font-size: 40px;
    font-weight: 700;
    text-align: center;
    color: #666666;
    margin: 20px 0px 10px;
}


@media (max-width: 1860px) {
    .container {
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        gap: 50px;
    }

    .container div:first-of-type>div>a>img:last-of-type {
        width: 40%;
    }

    .container div:first-of-type>div>img:first-of-type {
        width: 70%;
        margin-left: 0px;
    }
}


@media (max-width: 763px) {
    .container>div:first-of-type {
        min-width: 90vw !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .container>div:first-of-type>div {
        min-width: 90% !important;
        align-self: center;
    }

    .contentRight img {
        max-width: 70vw;
    }

    .container div:first-of-type>div>img:first-of-type {
        width: 90%;
        margin-left: 0px;
    }

    .container .containerUl li {
        font-size: 16px;
        padding-right: 10px;
    }
}


@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(236, 103, 21, .7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}

@keyframes pulseWithoutBoxShadow {
    0% {
        transform: scale(0.95);
    }

    70% {
        transform: scale(1);
    }

    100% {
        transform: scale(0.95);
    }
}